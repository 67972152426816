document.addEventListener('DOMContentLoaded', e => {
	const nav = document.querySelector('body>nav');
		  observer = new IntersectionObserver(entries => {
						const [entry] = entries;
						if(entry.isIntersecting){
							nav.classList.remove('scrolled');
						}else{
							nav.classList.add('scrolled');
						}
						
					},{
						threshold:0.33,
						rootMargin: '0px 0px 0px 0px'
					});
	
	try{
		observer.observe(document.querySelector('body>header'));
	}catch(e){
		//page has no header. nbd.
	}
});